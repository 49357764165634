import {controller, target} from '@github/catalyst'

@controller
class BypassReasonSelectorElement extends HTMLElement {
  @target submitBtn: HTMLButtonElement

  // Used by Secret Scanning Push Protection to ensure a bypass reason is selected and checkmarks are hidden
  reasonSelected() {
    this.submitBtn.disabled = false
  }

  // Used by Secret Scanning Push Protection to copy blob editor fields to bypass form to pass onto edit form after
  copyBlobFields() {
    const blobEditorForm = document.querySelector<HTMLFormElement>('.js-blob-form')!
    const bypassForm = document.querySelector<HTMLFormElement>('.js-bypass-form')!
    if (!(blobEditorForm instanceof HTMLFormElement) || !(bypassForm instanceof HTMLFormElement)) return

    this.createHiddenInputField(
      bypassForm,
      'value',
      document.querySelector<HTMLTextAreaElement>('.js-blob-contents')!.value,
    )
    this.createHiddenInputField(
      bypassForm,
      'description',
      document.querySelector<HTMLTextAreaElement>('#commit-description-textarea')!.value,
    )
    for (const element of blobEditorForm.elements) {
      if (element instanceof HTMLInputElement && element.name) {
        // eslint-disable-next-line github/authenticity-token
        if (!element.name.includes('gitignore') && element.name !== 'authenticity_token') {
          this.createHiddenInputField(bypassForm, element.name, element.value)
        }
      }
    }
  }

  private createHiddenInputField(form: HTMLFormElement, name: string, value: string) {
    const copyInput = document.createElement('input')
    copyInput.type = 'hidden'
    copyInput.name = name
    copyInput.id = `${name}_hidden`
    copyInput.value = value

    form.appendChild(copyInput)
    copyInput.required = true
  }
}
